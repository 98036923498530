<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-top m-0">
            <div class="col-lg-6 col-md-12 p-3">
                <div class="banner-content">
                    <span class="sub-title">Prueba gratuita por 30 días</span>
                    <h1>La App de control de asistencia de personal que automatiza y simplifica tu gestión diaria</h1>
                    <p>Optimice la gestión de su equipo con nuestro <strong>software</strong> avanzado de <strong>control de asistencia</strong>. Automatice registros, mejore la puntualidad y obtenga informes detallados en tiempo real. Ideal para <strong>empresas que buscan eficiencia</strong> y control absoluto en la gestión de su personal.</p>
                    <a routerLink="/como-funciona" class="default-btn">¿Cómo funciona?</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-image">
                    <img src="assets/img/banner/software_control_de_asistencia.webp" alt="App de control de asistencia y gestión de personal" width="1024" height="768">
                </div>
            </div>
        </div>
    </div>
<!--<div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>    
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
    <div class="banner-shape6"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="banner-shape7"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="banner-shape8"><img src="assets/img/shape/shape11.png" alt="image"></div>-->   
</div>